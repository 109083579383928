<template>
  <div
    id="openModal"
    class="modal modal-info"
    :class="{ 'modal--open': isAskBeforeNextOpen }"
  >
    <div class="modal-dialog modal-dialog--small modal-dialog--centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Уважаемый акционер</h4>
          <p>
            У вас осталось {{ remCount }} нераспределенных голосов. Перейти к
            следующему вопросу или распределить голоса?
          </p>
        </div>
        <div class="modal-body">
          <a
            class="info-guide-item__link"
            href="#"
            @click.prevent="$emit('distribute-votes')"
            >Распределить голоса</a
          >
        </div>
        <div class="modal-footer">
          <a
            class="info-guide-item__link info-guide-item__link--next"
            href="#"
            @click.prevent="$emit('next-question')"
            >Следующий вопрос</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAskBeforeNextOpen: {
      type: Boolean,
      default: false,
    },
    remCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>