<template>
  <div class="back-button__icon">
    <svg
      class="back-button__svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 6H1M1 6L6 1M1 6L6 11"
        stroke="#6F7474"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "BackButtonIcon",
}
</script>