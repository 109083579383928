<template>
  <div class="tooltip tooltip--title">
    <TooltipIcon />
    <div v-if="text" class="tooltip__text tooltip__text--title">
      <p>
        {{ text }}
      </p>
    </div>
    <div v-if="paragraphs" class="tooltip__text tooltip__text--title">
      <p v-for="(paragraph, i) in paragraphs" :key="i">
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<script>
import TooltipIcon from "@/components/icons/TooltipIcon";

export default {
  name: "Tooltip",
  props: {
    text: {
      type: [Boolean, String],
      default: false
    },
    paragraphs: {
      type: [Boolean, Array],
      default: false,
    }
  },
  components: {
    TooltipIcon,
  },
}
</script>