<template>
  <div>
    <div class="button-group button-group--question text-center">
      <template v-if="!isMarked">
        <a class="btn btn--green btn--200 btn--small" :class="{ 'btn--full': isFullInterface }" href="#" @click.prevent="enableFullInterface"
          >За
          <span class="btn__icon btn__icon--green" :class="{ 'btn__icon--active': isFullInterface }">
            <SelectedIcon />
          </span>
        </a>
        <a
          class="btn btn--green btn--200 mr-10 ml-10 btn--small"
          :class="{ 'btn--full-red': voteType === 'no' }"
          href="#"
          @click.prevent="distributeEqually('no')"
          >Против
          <span class="btn__icon btn__icon--red" :class="{ 'btn__icon--active': voteType === 'no' }">
            <SelectedIcon />
          </span>
        </a>
        <a
          class="btn btn--green btn--200 btn--small"
          :class="{ 'btn--full-orange': voteType === 'abstain' }"
          href="#"
          @click.prevent="distributeEqually('abstain')"
          >Воздержаться
          <span class="btn__icon btn__icon--orange" :class="{ 'btn__icon--active': voteType === 'abstain' }">
            <SelectedIcon />
          </span>
        </a>
      </template>
      <template v-if="isMarked">
        <div class="card__title card__title--group mb-24">
          <h3>
            Введите количество голосов <span>(не более {{ canNum * totalCount }})</span>
          </h3>
        </div>
        <form class="form" action="#">
          <div class="form__group form__group--center form__group--item">
            <div class="form__item form__item--200 form__item--active mr-10 ml-10" :class="specialMarkClasses('no')">
              <span class="form__control-name form__control-name--active form__control-name--red">Против</span>
              <input class="form__control" type="number" placeholder="" v-model.number="noCount" />
              <ActiveFieldIcon color="red" v-if="voteType === 'no'" />
              <div class="form__control-alert" v-if="showValidMessage('no')">Нельзя ввести больше голосов чем у вас осталось</div>
            </div>
            <div class="form__item form__item--200 form__item--active" :class="specialMarkClasses('yes')">
              <span class="form__control-name form__control-name--active form__control-name--orange">Воздержаться</span>
              <input class="form__control" type="number" placeholder="" v-model.number="abstainCount" />
              <ActiveFieldIcon color="orange" v-if="voteType === 'abstain'" />
              <div class="form__control-alert" v-if="showValidMessage('abstain')">Нельзя ввести больше голосов чем у вас осталось</div>
            </div>
          </div>
        </form>
      </template>
    </div>
    <div class="info-guide-item justify-content-center mt-27" v-if="isFullInterface">
      <DistributeEquallyIcon />
      <a class="info-guide-item__link" href="#" @click.prevent="isDistEquallyOpen = true">Распределить голоса поровну</a>
    </div>
    <div class="card__council" v-if="curQ">
      <div class="council">
        <div class="council__items">
          <CumulativeItem
            v-for="c in candidates"
            :key="c.id"
            :candidate="c"
            :isFullInterface="isFullInterface"
            :voting="voting"
            :curQ="curQ"
            :isDistEqually="isDistEqually"
            :distManuallyCount="distManuallyCount"
          />
        </div>

        <div class="council__clear" v-if="isFullInterface">
          <div class="info-guide-item justify-content-center">
            <EraserIcon />
            <a class="info-guide-item__link info-guide-item__link--clear" href="#" @click.prevent="clearDistribution">Очистить распределение</a>
          </div>
        </div>
      </div>
    </div>
    <DistributeEqually
      :isDistEquallyOpen="isDistEquallyOpen"
      @distribute-equally="distributeEqually('yes')"
      @distribute-manually="distributeManually"
    />
  </div>
</template>

<script>
import qData from "@/dummies/bulletin.json";

import EraserIcon from "@/components/icons/EraserIcon";
import DistributeEquallyIcon from "@/components/icons/DistributeEquallyIcon";
import DistributeEqually from "@/components/bulletin/DistributeEqually";
import CumulativeItem from "@/components/bulletin/CumulativeItem";
import ActiveFieldIcon from "@/components/icons/ActiveFieldIcon";
import SelectedIcon from "@/components/icons/SelectedIcon";

export default {
  props: {
    curQ: {
      type: [Number, Boolean],
      default: false,
    },
    isMarked: {
      type: Boolean,
      default: false,
    },
    voting: {
      type: [Array, Boolean],
      default: false,
    },
  },
  computed: {
    candidateVotes() {
      return this.votingLocal[this.curQ - 1].candidates;
    },
    isFullInterface() {
      const isSomeYes = this.candidateVotes.some((c) => c.castCount.yes);
      if (isSomeYes) this.fullInterfaceEnabled = true;
      return this.fullInterfaceEnabled || this.isMarked;
    },
    voteType() {
      if (this.candidateVotes.every((c) => c.castCount.no === this.totalCount)) return "no";
      if (this.candidateVotes.every((c) => c.castCount.abstain === this.totalCount)) return "abstain";
      else return false;
    },
    noCount: {
      get() {
        return this.castCount("no");
      },
      set(val) {
        val = Math.round(val);
        this.vote((type, casts) => {
          if (type === "no") casts[type] = val / this.canNum;
        });
      },
    },
    abstainCount: {
      get() {
        return this.castCount("abstain");
      },
      set(val) {
        val = Math.round(val);
        this.vote((type, casts) => {
          if (type === "abstain") casts[type] = val / this.canNum;
        });
      },
    },
  },
  data() {
    return {
      canNum: this.voting[this.curQ - 1].candidates.length,
      totalCount: qData.vote_count,
      candidates: qData.data[this.curQ - 1].candidates,
      votingLocal: this.voting,
      isDistEquallyOpen: false,
      isDistEqually: false,
      distManuallyCount: 0,
      fullInterfaceEnabled: false,
    };
  },
  methods: {
    enableFullInterface() {
      this.vote((type, casts) => {
        casts[type] = 0;
      });
      this.fullInterfaceEnabled = true;
    },
    distributeManually() {
      this.distManuallyCount += 1;
      this.isDistEquallyOpen = false;
    },
    distributeEqually(type) {
      this.vote((_type, casts) => {
        if (_type === type) casts[_type] = this.totalCount;
        else casts[_type] = 0;
      });
      this.isDistEquallyOpen = false;
      this.isDistEqually = true;
      if (type !== "yes") this.fullInterfaceEnabled = false;
    },
    clearDistribution() {
      this.vote((type, casts) => {
        casts[type] = 0;
      });
    },
    castCount(type) {
      return this.votingLocal[this.curQ - 1].candidates[0].castCount[type] * this.canNum;
    },
    vote(fn) {
      const newData = { ...this.votingLocal };
      const curData = newData[this.curQ - 1];
      curData.candidates.forEach((v) => {
        for (const _type in v.castCount) fn(_type, v.castCount);
      });
      const totalCastCount = curData.candidates.reduce((prev, cur) => prev + cur.castCount.yes + cur.castCount.no + cur.castCount.abstain, 0);
      const remCount = this.canNum * this.totalCount - totalCastCount;
      curData.remCount = remCount;
      this.votingLocal = newData;
    },
    specialMarkClasses(type) {
      const typeToClassMap = {
        yes: "form__item--active-for",
        no: "form__item--active-for-red",
        abstain: "form__item--active-for-orange",
      };

      return {
        [typeToClassMap[type]]: this.voteType === type,
        "form__item--error": this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount(type) > 0,
      };
    },
    showValidMessage(type) {
      return this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount(type) > 0;
    },
  },
  components: {
    EraserIcon,
    DistributeEquallyIcon,
    DistributeEqually,
    CumulativeItem,
    ActiveFieldIcon,
    SelectedIcon,
  },
};
</script>