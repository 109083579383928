<template>
  <div class="special-marks">
    <div class="accordion accordion--marks">
      <div class="accordion__header" :class="{ 'accordion__header--open': isExpanded }" @click="isExpanded = !isExpanded">
        Особые отметки голосования
        <span class="accordion__header-arrow">
          <CollapseExpandIcon />
        </span>
      </div>
      <collapse-transition>
        <div class="accordion__content accordion__content-row" v-show="isExpanded">
          <div class="accordion__content-item">
            <div class="form__check">
              <div class="form__check-box" :class="{ 'form__check-box--active': isFirstComputed }"></div>
              <input class="form__check-input" v-model="isFirstComputed" type="checkbox" id="defaultCheck1" />
              <label class="form__check-label" for="defaultCheck1">
                Голосование осуществляется в соответствии с указаниями приобретателей акций, переданных после даты составления списка участников.
              </label>
              <Tooltip
                text="В случае, если голосование осуществляется в соответствии с
                    указаниями лиц, которые приобрели акции после даты
                    составления списка лиц, имеющих право на участие в Общем
                    собрании акционеров, сделайте отметку о причинах заполнения
                    поля и укажите количество голосов, отданных за каждый
                    вариант голосования, в полях рядом с выбранным вариантом
                    голосования"
              />
            </div>
          </div>
          <div class="accordion__content-item">
            <div class="form__check">
              <div class="form__check-box" :class="{ 'form__check-box--active': isSecondComputed }"></div>
              <input class="form__check-input" v-model="isSecondComputed" type="checkbox" id="defaultCheck2" />
              <label class="form__check-label" for="defaultCheck2"> Часть акций передана после даты составления списка участников. </label>
              <Tooltip
                text="В случае, если после даты составления списка лиц, имеющих
                    право на участие в Общем собрании акционеров переданы не все
                    акции, сделайте отметку о причинах заполнения поля и в поле
                    рядом с выбранным вариантом голосования укажите количество
                    голосов, отданных за выбранный вариант"
              />
            </div>
          </div>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import CollapseExpandIcon from "@/components/icons/CollapseExpandIcon";
import Tooltip from "@/components/ui/Tooltip";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

export default {
  data() {
    return {
      isExpanded: false,
      isFirst: false,
      isSecond: false,
    };
  },
  computed: {
    isFirstComputed: {
      get() {
        return this.isFirst;
      },
      set(checked) {
        if (checked) this.isSecond = false;
        this.isFirst = checked;
        this.$emit("marked", checked);
      },
    },
    isSecondComputed: {
      get() {
        return this.isSecond;
      },
      set(checked) {
        if (checked) this.isFirst = false;
        this.isSecond = checked;
        this.$emit("marked", checked);
      },
    },
  },
  components: {
    CollapseExpandIcon,
    Tooltip,
    CollapseTransition,
  },
};
</script>