<template>
  <div class="card__council">
    <div class="council">
      <div class="council__items">
        <ComplexItem v-for="c in candidates" :key="c.id" :candidate="c" :voting="voting" :isMarked="isMarked" :curQ="curQ" />
      </div>
      <div class="council__clear">
        <div class="info-guide-item justify-content-center">
          <EraserIcon />
          <a class="info-guide-item__link info-guide-item__link--clear" href="#" @click.prevent="clearDistribution">Очистить распределение</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qData from "@/dummies/bulletin.json";

import EraserIcon from "@/components/icons/EraserIcon";
import ComplexItem from "@/components/bulletin/ComplexItem";

export default {
  props: {
    curQ: {
      type: [Number, Boolean],
      default: false,
    },
    isMarked: {
      type: Boolean,
      default: false,
    },
    voting: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    return {
      totalCount: qData.vote_count,
      candidates: qData.data[this.curQ - 1].candidates,
      votingLocal: this.voting,
    };
  },
  methods: {
    clearDistribution() {
      const data = { ...this.votingLocal };
      const curData = data[this.curQ - 1];
      curData.candidates.forEach((v) => {
        for (const type in v.castCount) v.castCount[type] = 0;
      });
      const totalCastCount = curData.candidates.reduce((prev, cur) => prev + cur.castCount.yes + cur.castCount.no + cur.castCount.abstain, 0);
      const remCount = this.totalCount - totalCastCount;
      curData.remCount = remCount;
      this.votingLocal = data;
    },
  },
  components: { EraserIcon, ComplexItem },
};
</script>