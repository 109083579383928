<template>
  <div class="main__inner" v-if="voting">
    <div class="back-button meetings__back-button">
      <div class="back-button__icon">
        <svg class="back-button__svg" width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6H1M1 6L6 1M1 6L6 11" stroke="#6F7474" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="back-button__text">
        <router-link class="back-button__link" to="/">Назад</router-link>
      </div>
    </div>
    <div class="title">
      <h2>Бюллетень для голосования</h2>
      <Tooltip
        :paragraphs="[
          `Дата проведения годового общего собрания: 24 апреля`,
        ]"
      />
    </div>

    <InfoGuideVote :dateEnd="'23:59 23.04.2021 (МСК)'" :accountNumber="'000000000000001473772'" />
    <SpecialMarks @marked="isMarked = $event" />
    <div class="card card--question mt-15">
      <div class="card__header">
        <div class="card__header-left">
          <div class="card__header-title">
            <h2>Вопрос №{{ curQ }}</h2>
            <a class="card__header-link card__header-link--question" href="#" @click.prevent="isMaterialsOpen = true">Материалы</a>
          </div>
          <div class="card__header-subtitle">
            {{ qData[curQ - 1].name }}
          </div>
        </div>
        <div class="card__header-right">
          <ul class="votes-list" v-if="qData[curQ - 1].type !== 2">
            <li class="votes-list__item">Количество голосов:</li>
            <li class="votes-list__value">{{ totalCount }}</li>
          </ul>
          <ul class="votes-list" v-else>
            <li class="votes-list__item">Кумулятивных:</li>
            <li class="votes-list__value">
              {{ totalCount * qData[curQ - 1].candidates.length }}
            </li>
          </ul>
          <ul class="votes-list">
            <li class="votes-list__item">Нераспределенных:</li>
            <li class="votes-list__value">
              {{ Math.round(voting[curQ - 1].remCount) }}
            </li>
          </ul>
        </div>
      </div>
      <div class="card__body">
        <div class="card__title">
          <h3>Решение по вопросу №{{ curQ }}</h3>
        </div>
        <div
          class="card__header-subtitle"
          :class="{ 'card__header-subtitle--mobile card__header-subtitle--mobile500': qData[curQ - 1].description.length < 100 }"
        >
          <div
            :class="
              isFullDesc
                ? 'card__header-subtitle--open'
                : 'card__header-subtitle--hide'
            "
          >
            <p v-html="qData[curQ - 1].description"></p>
          </div>
          <button class="card__header-link card__header-link--more" @click="isFullDesc = !isFullDesc" id="showMoreSubtitle">
            {{ isFullDesc ? "Свернуть" : "Подробнее" }}
          </button>
        </div>
        <SimpleQ :isMarked="isMarked" :voting="voting" :curQ="curQ" v-if="qData[curQ - 1].type === 1" />
        <CumulativeQ :isMarked="isMarked" :voting="voting" :curQ="curQ" v-if="qData[curQ - 1].type === 2" />
        <ComplexQ :isMarked="isMarked" :voting="voting" :curQ="curQ" v-if="qData[curQ - 1].type === 3" />
      </div>
      <div class="card__footer">
        <div class="info-guide-item" :class="{ 'info-guide-item--hide': curQ === 1 }">
          <PreviousArrowIcon />
          <a class="info-guide-item__link info-guide-item__link--next" href="#" @click.prevent="prevQuestion">Назад</a>
        </div>
        <div class="info-guide-item" v-if="voting[curQ - 1].remCount >= 0">
          <a class="info-guide-item__link" href="#" @click.prevent="nextQuestion">{{ curQ === qData.length ? "Отправить бюллетень" : "Следующий вопрос" }}</a>
          <NextArrowIcon />
        </div>
      </div>
    </div>
    <AskBeforeNext
      :isAskBeforeNextOpen="isAskBeforeNextOpen"
      :remCount="voting[curQ - 1].remCount"
      @next-question="nextQuestion"
      @distribute-votes="isAskBeforeNextOpen = false"
    />
    <SendBulletin :isSendBulletinOpen="isSendBulletinOpen" @close-send-bulletin="isSendBulletinOpen = false" />
    <Materials :isMaterialsOpen="isMaterialsOpen" :curQ="curQ" @close-materials="isMaterialsOpen = false" />
  </div>
</template>

<script>
import Materials from "@/components/bulletin/Materials";
import SendBulletin from "@/components/bulletin/SendBulletin";
import InfoGuideVote from "@/components/bulletin/InfoGuideVote";
import CumulativeQ from "@/components/bulletin/CumulativeQ";
import ComplexQ from "@/components/bulletin/ComplexQ";
import AskBeforeNext from "@/components/bulletin/AskBeforeNext";
import SpecialMarks from "@/components/bulletin/SpecialMarks";
import SimpleQ from "@/components/bulletin/SimpleQ";

import qData from "@/dummies/bulletin.json";

import NextArrowIcon from "@/components/icons/NextArrowIcon";
import PreviousArrowIcon from "@/components/icons/PreviousArrowIcon";
import BackButtonIcon from "@/components/icons/BackButtonIcon";

import Tooltip from "@/components/ui/Tooltip";

export default {
  data() {
    return {
      curQ: 1,
      qNum: qData.data.length,
      totalCount: qData.vote_count,
      isFullDesc: true,
      isMaterialsOpen: false,
      isSendBulletinOpen: false,
      isAskBeforeNextOpen: false,
      qData: qData.data,
      voting: false,
      isMarked: false,
    };
  },
  mounted() {
    const data = this.qData.map((d, id) => {
      const remCount = d.type !== 2 ? Math.round(this.totalCount) : Math.round(this.totalCount * d.candidates.length);
      const candidates =
        d.type === 1
          ? false
          : d.candidates.map((_c, _id) => ({
              id: _id,
              castCount: { yes: 0, no: 0, abstain: 0 },
            }));

      return {
        id,
        type: false,
        remCount,
        castCount: { yes: 0, no: 0, abstain: 0 },
        candidates,
      };
    });

    this.voting = data;
  },
  methods: {
    nextQuestion() {
      if (this.voting[this.curQ - 1].remCount) {
        if (!this.isAskBeforeNextOpen) return (this.isAskBeforeNextOpen = true);
        else this.isAskBeforeNextOpen = false;
      }
      if (this.curQ === this.qNum) return (this.isSendBulletinOpen = true);
      this.curQ += 1;
    },
    prevQuestion() {
      this.curQ -= 1;
    },
  },
  components: {
    Materials,
    SendBulletin,
    InfoGuideVote,
    Tooltip,
    BackButtonIcon,
    CumulativeQ,
    ComplexQ,
    SimpleQ,
    NextArrowIcon,
    PreviousArrowIcon,
    AskBeforeNext,
    SpecialMarks,
  },
};
</script>
