<template>
  <span class="form__control-icon btn__icon" :class="'btn__icon--' + color + '-full'">
    <svg class="btn__icon-svg" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.17903L2.98317 5L7 1" stroke="#2ba527" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <defs>
        <linearGradient id="paint0_linear" x1="1" y1="1" x2="4.69231" y2="6.53846" gradientUnits="userSpaceOnUse">
          <stop stop-color="#45AE00" />
          <stop offset="1" stop-color="#08944A" />
        </linearGradient>
      </defs>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "green",
    },
  },
};
</script>