<template>
  <div class="button-group button-group--question text-center">
    <template v-if="!isMarked">
      <a class="btn btn--green btn--200 btn--small" :class="{ 'btn--full': voteType === 'yes' }" href="#" @click.prevent="yesCount = totalCount"
        >За
        <span class="btn__icon btn__icon--green" :class="{ 'btn__icon--active': voteType === 'yes' }">
          <SelectedIcon />
        </span>
      </a>
      <a
        class="btn btn--green btn--200 mr-10 ml-10 btn--small"
        :class="{ 'btn--full-red': voteType === 'no' }"
        href="#"
        @click.prevent="noCount = totalCount"
        >Против
        <span class="btn__icon btn__icon--red" :class="{ 'btn__icon--active': voteType === 'no' }">
          <SelectedIcon />
        </span>
      </a>
      <a
        class="btn btn--green btn--200 btn--small"
        :class="{ 'btn--full-orange': voteType === 'abstain' }"
        href="#"
        @click.prevent="abstainCount = totalCount"
        >Воздержаться
        <span class="btn__icon btn__icon--orange" :class="{ 'btn__icon--active': voteType === 'abstain' }">
          <SelectedIcon />
        </span>
      </a>
    </template>
    <template v-if="isMarked">
      <div class="card__title card__title--group mb-24">
        <h3>
          Введите кол-во голосов <span>(не более {{ totalCount }})</span>
        </h3>
      </div>
      <form class="form" action="#">
        <div class="form__group form__group--center form__group--item">
          <div class="form__item form__item--200 form__item--active" :class="specialMarkClasses('yes')">
            <span class="form__control-name form__control-name--active form__control-name--green">За</span>
            <input class="form__control" type="number" placeholder="" v-model.number="yesCount" />
            <ActiveFieldIcon color="green" v-if="voteType === 'yes'" />
            <div class="form__control-alert" v-if="showValidMessage('yes')">Нельзя ввести больше голосов чем у вас осталось</div>
          </div>
          <div class="form__item form__item--200 form__item--active mr-10 ml-10" :class="specialMarkClasses('no')">
            <span class="form__control-name form__control-name--active form__control-name--red">Против</span>
            <input class="form__control" type="number" placeholder="" v-model.number="noCount" />
            <ActiveFieldIcon color="red" v-if="voteType === 'no'" />
            <div class="form__control-alert" v-if="showValidMessage('no')">Нельзя ввести больше голосов чем у вас осталось</div>
          </div>
          <div class="form__item form__item--200 form__item--active" :class="specialMarkClasses('abstain')">
            <span class="form__control-name form__control-name--active form__control-name--orange">Воздержаться</span>
            <input class="form__control" type="number" placeholder="" v-model.number="abstainCount" />
            <ActiveFieldIcon color="orange" v-if="voteType === 'abstain'" />
            <div class="form__control-alert" v-if="showValidMessage('abstain')">Нельзя ввести больше голосов чем у вас осталось</div>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import qData from "@/dummies/bulletin.json";

import ActiveFieldIcon from "@/components/icons/ActiveFieldIcon";
import SelectedIcon from "@/components/icons/SelectedIcon";

export default {
  props: {
    curQ: {
      type: [Number, Boolean],
      default: false,
    },
    isMarked: {
      type: Boolean,
      default: false,
    },
    voting: {
      type: [Array, Boolean],
      default: false,
    },
  },
  computed: {
    voteType() {
      const curCastCount = this.votingLocal[this.curQ - 1].castCount;
      return Object.keys(curCastCount).filter((v) => curCastCount[v] === this.totalCount)[0];
    },
    yesCount: {
      get() {
        return this.castCount("yes");
      },
      set(val) {
        this.vote("yes", val);
      },
    },
    noCount: {
      get() {
        return this.castCount("no");
      },
      set(val) {
        this.vote("no", val);
      },
    },
    abstainCount: {
      get() {
        return this.castCount("abstain");
      },
      set(val) {
        this.vote("abstain", val);
      },
    },
  },
  data() {
    return {
      totalCount: qData.vote_count,
      votingLocal: this.voting,
      qData: qData.data,
    };
  },
  methods: {
    vote(type, val) {
      val = Math.round(val);
      const newData = [...this.votingLocal];
      const curData = newData[this.curQ - 1];
      for (const _type in curData.castCount) {
        if (_type === type) curData.castCount[_type] = val;
        else if (val === this.totalCount) curData.castCount[_type] = 0;
      }
      curData.castCount[type] = val;
      curData.remCount = this.totalCount - (curData.castCount.yes + curData.castCount.no + curData.castCount.abstain);
      this.votingLocal = newData;
    },
    castCount(type) {
      return this.votingLocal[this.curQ - 1].castCount[type];
    },
    specialMarkClasses(type) {
      const typeToClassMap = {
        yes: "form__item--active-for",
        no: "form__item--active-for-red",
        abstain: "form__item--active-for-orange",
      };

      return {
        [typeToClassMap[type]]: this.voteType === type,
        "form__item--error": this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount(type),
      };
    },
    showValidMessage(type) {
      return this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount(type);
    },
  },
  components: { ActiveFieldIcon, SelectedIcon },
};
</script>