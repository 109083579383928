<template>
  <div class="council__item">
    <div class="council__item-left">
      <div class="council__item-logo">
        <img class="council__item-image" :src="getImgUrl(candidate.img)" alt="Кандидат" />
      </div>
      <div class="council__item-data">
        <div class="council__item-name">
          {{ candidate.name }}
        </div>
        <div class="council__item-position">{{ candidate.position }}</div>
      </div>
    </div>
    <div class="council__item-right council__item-right--active">
      <div class="button-group button-group--question text-center">
        <template v-if="!isMarked">
          <a
            class="btn btn--green btn--medium-s btn--small"
            :class="{ 'btn--full': voteType === 'yes' }"
            href="#"
            @click.prevent="yesCount = totalCount / canNum"
            >За
            <span class="btn__icon btn__icon--green" :class="{ 'btn__icon--active': voteType === 'yes' }">
              <SelectedIcon />
            </span>
          </a>
          <a
            class="btn btn--green btn--medium-s btn--small mr-5 ml-5"
            :class="{ 'btn--full-red': voteType === 'no' }"
            href="#"
            @click.prevent="noCount = totalCount / canNum"
            >Против
            <span class="btn__icon btn__icon--red" :class="{ 'btn__icon--active': voteType === 'no' }">
              <SelectedIcon />
            </span>
          </a>
          <a
            class="btn btn--green btn--medium-s btn--small"
            :class="{ 'btn--full-orange': voteType === 'abstain' }"
            href="#"
            @click.prevent="abstainCount = totalCount / canNum"
            >Воздержаться
            <span class="btn__icon btn__icon--orange" :class="{ 'btn__icon--active': voteType === 'abstain' }">
              <SelectedIcon />
            </span>
          </a>
        </template>
        <template v-if="isMarked">
          <form class="form" action="#">
            <div class="form__group form__group--center form__group--item">
              <div class="form__item form__item--100 form__item--active" :class="specialMarkClasses('yes')">
                <span class="form__control-name form__control-name--active form__control-name--green">За</span>
                <input class="form__control" type="number" placeholder="" v-model.number="yesCount" />
                <ActiveFieldIcon color="green" v-if="voteType === 'yes'" />
                <div class="form__control-alert" v-if="showValidMessage('yes')">Превышен лимит</div>
              </div>
              <div class="form__item form__item--100 form__item--active mr-10 ml-10" :class="specialMarkClasses('no')">
                <span class="form__control-name form__control-name--active form__control-name--red">Против</span>
                <input class="form__control" type="number" placeholder="" v-model.number="noCount" />
                <ActiveFieldIcon color="red" v-if="voteType === 'no'" />
                <div class="form__control-alert" v-if="showValidMessage('no')">Превышен лимит</div>
              </div>
              <div class="form__item form__item--100 form__item--active" :class="specialMarkClasses('abstain')">
                <span class="form__control-name form__control-name--active form__control-name--orange">Воздержаться</span>
                <input class="form__control" type="number" placeholder="" v-model.number="abstainCount" />
                <ActiveFieldIcon color="orange" v-if="voteType === 'abstain'" />
                <div class="form__control-alert" v-if="showValidMessage('abstain')">Превышен лимит</div>
              </div>
            </div>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import qData from "@/dummies/bulletin.json";

import SelectedIcon from "@/components/icons/SelectedIcon";
import ActiveFieldIcon from "@/components/icons/ActiveFieldIcon";

export default {
  props: {
    curQ: {
      type: [Number, Boolean],
      default: false,
    },
    candidate: {
      type: [Object, Boolean],
      default: false,
    },
    isMarked: {
      type: Boolean,
      default: false,
    },
    voting: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    return {
      canNum: this.voting[this.curQ - 1].candidates.length,
      totalCount: qData.vote_count,
      votingLocal: this.voting,
    };
  },
  computed: {
    voteType() {
      return Object.keys(this.castCount()).filter((v) => this.castCount()[v] === this.totalCount / this.canNum)[0];
    },
    yesCount: {
      get() {
        return this.castCount("yes");
      },
      set(val) {
        this.vote("yes", val);
      },
    },
    noCount: {
      get() {
        return this.castCount("no");
      },
      set(val) {
        this.vote("no", val);
      },
    },
    abstainCount: {
      get() {
        return this.castCount("abstain");
      },
      set(val) {
        this.vote("abstain", val);
      },
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/" + pic);
    },
    castCount(type) {
      const castCount = this.votingLocal[this.curQ - 1].candidates[this.candidate.id].castCount;
      if (type) return castCount[type];
      else return castCount;
    },
    vote(type, val) {
      val = Math.round(val);
      const newData = { ...this.votingLocal };
      const curData = newData[this.curQ - 1];
      const curCastCount = newData[this.curQ - 1].candidates[this.candidate.id].castCount;
      for (const _type in curCastCount) {
        if (_type === type) curCastCount[_type] = val;
        else if (val === this.totalCount / this.canNum) curCastCount[_type] = 0;
      }
      const totalCastCount = curData.candidates.reduce((prev, cur) => prev + cur.castCount.yes + cur.castCount.no + cur.castCount.abstain, 0);
      const remCount = this.totalCount - totalCastCount;
      curData.remCount = remCount;
      this.votingLocal = newData;
    },
    specialMarkClasses(type) {
      const typeToClassMap = {
        yes: "form__item--active-for",
        no: "form__item--active-for-red",
        abstain: "form__item--active-for-orange",
      };

      return {
        [typeToClassMap[type]]: this.voteType === type,
        "form__item--error": this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount(type) > 0,
      };
    },
    showValidMessage(type) {
      return this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount(type) > 0;
    },
  },
  components: { SelectedIcon, ActiveFieldIcon },
};
</script>