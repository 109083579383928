<template>
  <div class="info-guide info-guide--vote">
      <div v-if="dateEnd" class="info-guide__item info-guide-end">
        <ul class="info-guide-end__list">
          <li class="info-guide-end__list-item">Окончание приема бюллетеней</li>
          <li class="info-guide-end__list-item">{{ dateEnd }}</li>
        </ul>
      </div>
      <div v-if="accountNumber" class="info-guide__item info-guide-end info-guide__item--number">
        <ul class="info-guide-end__list">
          <li class="info-guide-end__list-item">Номер ЛС</li>
          <li class="info-guide-end__list-item">{{ accountNumber }}</li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: "InfoGuideVote",
  props: {
    dateEnd: {
      type: [Boolean, String],
      default: false,
    },
    accountNumber: {
      type: [Boolean, String],
      default: false,
    },
  }
}
</script>