<template>
  <div class="council__item">
    <div class="council__item-left">
      <div class="council__item-logo">
        <img class="council__item-image" :src="getImgUrl(candidate.img)" alt="Кандидат" />
      </div>
      <div class="council__item-data">
        <div class="council__item-name">
          {{ candidate.name }}
        </div>
        <div class="council__item-position">{{ candidate.position }}</div>
      </div>
    </div>

    <div class="council__item-right council__item-right--active" v-if="isFullInterface">
      <div class="council__form">
        <div class="form form--flex form--council">
          <div class="form__item text" :class="specialMarkClasses">
            <span style="font-size:14px" class="form__control-name" :class="{ 'form__control-name--active': focused }">Введите количество голосов</span>
            <input
              class="form__control"
              type="number"
              :ref="candidate.id ? null : 'firstInput'"
              v-model.number="yesCount"
              @focus="focused = true"
              placeholder=""
            />
            <a class="form__control-del" href="javascript:void(0);" @click.prevent="vote(0)">
              <ClearIcon />
            </a>
            <div class="form__control-alert text-center" v-if="showValidMessage">Нельзя ввести больше голосов чем у вас осталось</div>
          </div>
          <a class="info-guide-item__link btn--chips flex-0" href="#" @click.prevent="castAllVotes">Отдать все голоса</a>
        </div>
      </div>
    </div>
    <div class="council__item-right" v-else></div>
  </div>
</template>

<script>
import qData from "@/dummies/bulletin.json";
import ClearIcon from "@/components/icons/ClearIcon";

export default {
  props: {
    candidate: {
      type: [Object, Boolean],
      default: false,
    },
    curQ: {
      type: [Number, Boolean],
      default: false,
    },
    isFullInterface: {
      type: Boolean,
      default: false,
    },
    voting: {
      type: [Array, Boolean],
      default: false,
    },
    isDistEqually: {
      type: Boolean,
      default: false,
    },
    distManuallyCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      canNum: this.voting[this.curQ - 1].candidates.length,
      totalCount: qData.vote_count,
      votingLocal: this.voting,
      isFocused: false,
    };
  },
  computed: {
    yesCount: {
      get() {
        return this.castCount > 0 || this.isFocused ? this.castCount : null;
      },
      set(val) {
        this.vote(val);
      },
    },
    showValidMessage() {
      return this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount > 0;
    },
    specialMarkClasses() {
      return {
        "form__item--active": this.focused,
        "form__item--error": this.votingLocal[this.curQ - 1].remCount < 0 && this.castCount > 0,
      };
    },
    focused: {
      get() {
        return this.isFocused || this.isDistEqually || this.castCount;
      },
      set(val) {
        this.isFocused = val;
      },
    },
    castCount() {
      return this.votingLocal[this.curQ - 1].candidates[this.candidate.id].castCount.yes;
    },
  },
  watch: {
    distManuallyCount() {
      if (this.$refs.firstInput) this.$refs.firstInput.focus();
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/" + pic);
    },
    castAllVotes() {
      this.vote(this.canNum * this.totalCount);
      this.focused = true;
    },
    vote(val) {
      val = Math.round(val);
      const newData = { ...this.votingLocal };
      const curData = newData[this.curQ - 1];
      curData.candidates[this.candidate.id].castCount.yes = val;
      const totalCastCount = curData.candidates.reduce((prev, cur) => prev + cur.castCount.yes + cur.castCount.no + cur.castCount.abstain, 0);
      const remCount = this.canNum * this.totalCount - totalCastCount;
      curData.remCount = remCount;
      this.votingLocal = newData;
    },
  },
  components: {
    ClearIcon,
  },
};
</script>