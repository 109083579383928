<template>
  <div id="materialsModal" class="modal" :class="{ 'modal--open': isMaterialsOpen }" ref="container">
    <div class="modal-dialog modal-dialog--appeal modal-dialog--centered" ref="dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-header__title">Материалы</h3>
          <a
            class="modal-header__close"
            href="#close"
            title="Close"
            @click="$emit('close-materials')"
          >
            <CloseIcon />
          </a>
        </div>
        <div class="modal-body modal-body--docs">
          <div class="meeting-docs__list">
            <ul class="documents-list">
              <li
                class="documents-list__item"
                v-for="m in qData[curQ - 1].materials"
                :key="m.id"
              >
                <DocumentsListIcon />
                <a class="documents-list__link" href="#">{{ m.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qData from "@/dummies/bulletin.json";

import CloseIcon from "@/components/icons/CloseIcon";
import DocumentsListIcon from "@/components/icons/DocumentsListIcon";

export default {
  data() {
    return { qData: qData.data };
  },
  mounted() {
    //Закрываем модалку по клику на backdrop'е
    this.$refs.container.addEventListener('click', (e) => {
      if (e.target == this.$refs.container || e.target == this.$refs.dialog) { //если клик был по backdrop'у
        this.$emit('close-materials');
      }
    });
  },
  components: {
    CloseIcon,
    DocumentsListIcon,
  },
  props: ["isMaterialsOpen", "curQ"],
};
</script>