<template>
  <div
    id="modalInfo"
    class="modal modal-info"
    :class="{ 'modal--open': isSendBulletinOpen }"
  >
    <div class="modal-dialog modal-dialog--small modal-dialog--centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Уважаемый акционер</h4>
          <p>
            Вы успешно заполнили бюллетень по всем вопросам повестки дня
            <br />собрания.
          </p>
        </div>
        <div class="modal-body">
          <router-link class="info-guide-item__link" to="/bulletin-sent"
            >Отправить бюллетень</router-link
          >
        </div>
        <div class="modal-footer">
          <a
            class="info-guide-item__link info-guide-item__link--next"
            href="#"
            @click="$emit('close-send-bulletin')"
            >Назад к голосованию</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isSendBulletinOpen"],
};
</script>